import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql, Link } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import ResultCard from "../components/resultComponents/ResultCard"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import OperatorImage from "../components/images/OperatorImage"
import styled from "styled-components"
import { device } from "../utils/mediaQueries"
import { AdserviceCompare } from "../components/CompareAdsense"

const ActionButton = styled(Link)`
  text-decoration: none;
  padding: 1rem 3rem;
  background-color: #a35dbc;
  color: white;
  font-weight: 700;
  word-wrap: break-word;
  text-align: center;
  margin: 0px auto;
  &:hover {
    background-color: violet;
  }
  @media ${device.tablet} {
    padding: 0.7rem;
  }
`

const CenteredTextArea = styled(TextArea)`
  text-align: center;
`

const FrontPage = ({ data }) => {
  const plansHook = data.allPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        border={true}
        pageName={"operaattorit"}
      />
    )
  })
  // const teliaPlans = data.telia.edges.map(plan => {
  //   return (
  //     <ResultCard
  //       key={plan.node.id}
  //       img={plan.node.img}
  //       name={plan.node.name}
  //       price={plan.node.price}
  //       calls={plan.node.callPrice}
  //       texts={plan.node.textPrice}
  //       internet={plan.node.internet}
  //       speed={plan.node.internetSpeed}
  //       openFee={plan.node.openFee}
  //       url={plan.node.url}
  //       border={true}
  //     />
  //   )
  // })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Puhelinoperaattorit Suomessa 2020 - Tutustu kaikkiin täällä!"
        desc="Suomessa toimivat puhelinoperaattorit ovat kaikki melko hyviä. Tutustu täältä mikä niistä voisi olla juuri sinulle se paras. Telia, DNA, Elisa vai Moi Mobiili?"
      />
      <Container>
        <Header1>Parhaat puhelinoperaattorit Suomessa 2020</Header1>
        <CenteredTextArea>
          Suomessa toimii tällä hetkellä 4 varteenotettavaa puhelinoperaattoria:
        </CenteredTextArea>
        <CenteredTextArea>
          <b>Telia, Elisa, Moi Mobiili ja DNA</b>. Nämä kaikki tarjoavat
          laadukkaita puhelinliittymiä, joista jokaiselle löytyy oma.
        </CenteredTextArea>
        <Header3>Tutustu alapuolelta operaattoreiden liittymiin</Header3>
        <CenteredTextArea>
          <b>
            Liittymät on lajiteltu edullisimmista liittymästä kalleimpaan
            kuukausimaksun mukaan.
          </b>
        </CenteredTextArea>
        <CenteredTextArea>
          Mikäli haluat verrata juuri sinulle edullisimman liittymän käytä{" "}
          <Link to="/puhelinliittymavertailu">puhelinliittymä vertailua</Link>
        </CenteredTextArea>

        <AdserviceCompare />

        <Header2>Kaikki puhelinoperaattorit Suomessa</Header2>
        <TextArea>
          Oletko kyllästynyt oman teleoperaattorisi toimintaan ja haluaisit ehkä
          vaihtaa liittymää parempaan? Nykyään on todella helppoa saada uusi ja
          parempi matkapuhelinliittymätarjous muilta teleoperaattoreilta, ja
          toisaalta hintavertailun tekeminen kannattaa aina.{" "}
        </TextArea>
        <TextArea>
          Alapuolelta voit tutustua kaikkiin Suomen puhelinoperaattoreihin tällä
          hetkellä. Voit etsiä mieluisimman liittymän juuri sinulle.
        </TextArea>
        <TextArea>
          Mutta mikä matkapuhelinoperaattoreista on paras, ja millä on kattavin
          verkko? Koska operaattorien tarjoamia liittymiä ja palveluja voi olla
          vaikea erottaa toisistaan, olemme koonneet tähän artikkeliin
          perustiedot isoimmista teleoperaattoreista ja niiden palveluista.
        </TextArea>
        <RowContainer
          title={
            "Voit tutustua myös jokaiseen operaattoriin tarkemmin alapuolelta"
          }
        >
          <Button link={"/dna/"} title={"Dna"} />
          <Button link={"/telia/"} title={"Telia"} />
          <Button link={"/moi/"} title={"Moi Mobiili"} />
          <Button link={"/dna/"} title={"DNA"} />
        </RowContainer>
        <Header3>Telia</Header3>
        <OperatorImage imgName={"teliaoperaattori.jpg"} />
        <TextArea>
          <a href="/telia/">Ruotsalainen matkapuhelinoperaattori Telia</a> on
          monille suomalaisille tuttu liittymäoperaattori, jonka suomalaiset
          tuntevat entisenä TeliaSonerana. Telia hankki omistukseensa
          suomalaisen Soneran vuonna 2002, ja tätä ennen sekä Telia että Sonera
          olivat monopoliasemassa Ruotsissa ja Suomessa. Soneran historia taas
          pohjautuu Suomen valtion omistamaan Telecom Finlandiin, joka
          tunnettiin Suomessa paremmin nimellä Tele. Telia toimii Suomen lisäksi
          muissa Pohjoismaissa ja Baltian maissa, jonka lisäksi se on myös
          tehnyt runsaasti yritysostoja Euroopan teleoperaattorimarkkinoilla
          omistaen osuuksia esimerkiksi Espanjassa ja Saksassa toimivilta
          teleoperaattoreilta.
        </TextArea>
        <TextArea>
          Puhelinoperaattoripalveluista Telia tarjoaa suomalaisille asiakkaille
          kiinteässä verkossa ja mobiiliverkossa toimivia laajakaistapalveluja.
          Yksityisasiakkaiden lisäksi Telialla on myös paljon toimintaa
          yrityspuolen yhteysratkaisujen tarjoamisessa. Nykyään Telia omistaa
          myös suomalaisen MTV-televisiokanavan sekä C More -palvelun. Telian
          4G-mobiiliverkko kuuluu Suomen kattavimpiin verkkoihin, ja sen
          toimialue kattaa jopa 99 prosenttia suomalaisista. 4G:n EU-roamingilla
          verkkoa pääsee selaamaan kohtuuhintaan myös Euroopan Unionin alueella.
          Telialla onkin Pohjoismaiden ja Baltian kattavin oma verkko, joten
          voit käyttää sitä huoletta Norjasta Liettuaan asti.
        </TextArea>
        <TextArea>
          Telialla on useita erilaisia puhelinliittymätyyppejä
          kuluttaja-asiakkaiden erilaisiin tarpeisiin. Liittymävalikoimasta
          löytyvät esimerkiksi rajattomia nettiliittymiä, prepaid-liittymiä ja
          perusliittymiä. Telia mainostaa omia liittymäpalvelujaan sellaisina,
          joihin ei ole erillistä sitoutumispakkoa. Yrityksen pääliittymätuote
          suomalaisilla teleoperaattorimarkkinoilla on Rajaton-liittymä, joka on
          luonteeltaan kiinteän mobiiliverkon ja kiinteähintaisen
          puhelinliittymän yhdistelmä. Rajaton-liittymällä asiakas voi soittaa
          ja lähettää rajattomasti tekstiviestejä veloituksetta, jonka lisäksi
          myös 4G-verkon mobiilinettiyhteys kuuluu hintaan. Kyseisen tuotemerkin
          liittymät lienevät kaikista suosituimpia, ja näitä tuotteita myös
          Telia mainostaa ja markkinoi aktiivisesti.
          <ul>
            <li>Telialla on lisäksi paljon etuja alle 28-vuotiaille</li>
            <li>
              Telian sähköisellä eSIM-kortilla korvaat pahvisen vanhan
              SIM-kortin
            </li>
            <li>
              Erilliset liittymät myös perheen pienimmille turvapalveineen ja
              rajoituksineen
            </li>
            <li>
              Telia kehittää myös kattavaa mobiilivarmennetta, asioiden
              internet-verkostoa sekä talous- ja terveyspalveluita
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Rajaton-liittymätyyppejä on kuluttaja-asiakkaille yhteensä kuusi
          erilaista, ja niiden kuukausihinnat vaihtelevat Rajaton Lite 0,2
          M-liittymän noin 8 eurosta kuukaudessa aina Rajaton 450 M:n 39,90
          euroon kuukaudessa. Kiinteähintaisten Rajaton-liittymien lisäksi
          Telialla on myös oma pienkäyttöön tarkoitettu Telia DOT -liittymä,
          jossa asiakas maksaa vain siitä osasta palvelua, jonka käyttää. Tämä
          voi olla hyvä vaihto ihmisille, jonka liittymän käyttö on hyvin
          rajoitettua, kuten esimerkiksi senioreille. Lisäksi Telia tarjoaa
          pelkkää mobiililaajakaistaa esimerkiksi tablettia varten. Myös
          mobiililaajakaistapalvelut käyttävät samaa Rajaton-brändiä.
        </TextArea>
        <Header3>DNA</Header3>
        <OperatorImage imgName={"dnaoperaattori.jpg"} />
        <TextArea>
          Muistatko vielä mainoslauseen ”Elämä kallis, DNA halpa”?{" "}
          <a href="/dna/">DNA eli entinen Finnet Oy</a> on suomalaislähtöinen
          matkapuhelinoperaattori ja tietoliikennetoimialalla toimiva yritys,
          joka sai alkunsa vuonna 2001 alueellisten puhelinyhtiöiden
          yhteenliittymänä. Vaikka yhtiö on listattu Telian tavoin Helsingin
          pörssiin, suurimman osan sen osakkeista omistaa nykyään norjalainen
          Telenor. DNA:n toiminta jakaantuu kuluttajien ja yritysten välille, ja
          kuluttajille DNA tarjoaa matkapuhelimia ja liittymiä, kiinteää
          laajakaistaa kotiin ja mobiililaajakaistaa puhelimiin, tietoturvaan
          liittyviä palveluja sekä erilaisia kanavapaketteja.
        </TextArea>
        <TextArea>
          Yksityishenkilöille tarjottavat puhelinliittymät on jaettu
          palveluominaisuuksien mukaan DNA Puhe-, DNA Rajaton-, DNA Jämpti- ja
          DNA Puhe Plus -liittymiin, joiden ominaisuudet vaihtelevat laajasti
          asiakkaiden tarpeen mukaan. Myös kaikki DNA:n tarjoamat
          puhelinliittymät toimivat rajattomalla netillä Suomessa ja
          Pohjoismaissa. Puhelinliittymien ohella DNA tarjoaa internet-liittymiä
          ja tv-kanavapaketteja. TV-kanavapaketit toimivat DNA TV-merkin
          alaisuudessa. DNA TV voi pitää sisällään useita erilaisia kanavia,
          jotka on räätälöity eri asiakasryhmille, kuten urheilun ystäville,
          elokuvafanaatikoille tai muuten vain ihmisille, jotka tykkäävät
          rentoutua television ääressä.
        </TextArea>
        <TextArea>
          Erikoisuutena valikoimassa on DNA Mix, jolla asiakas voi valita
          itselleen jopa 12 erilaista kanavaa ja vaihtaa niitä kuusi kertaa
          vuodessa. Mix-kanavat voivat sisältää lähes mitä tahansa aina
          urheilusta elokuviin, dokumenteista lastenohjelmiin asti. Yrityksille
          DNA tarjoaa tietoliikenne- ja liittymäpalveluita. Tarjolla on
          yrityskäyttöön tarkoitettuja mobiililiittymiä, esimerkiksi DNA
          Business -brändin alla toimivat Rajaton 4G-liittymät. Lisäksi
          yritykset voivat hyötyä DNA:n palveluista toimiston sisäisessä
          viestinnässä sekä esimerkiksi asiakaspalvelukeskuksissa. Yrityksen
          tarjonnasta löytyvät ratkaisut esimerkiksi yritysten vaihdepalvelujen
          hoitamiseen sekä palvelunumerojen päivittäiseen operoimiseen.
        </TextArea>
        <TextArea>
          Kuluttaja-asiakkaiden ja yritysasiakkaiden lisäksi DNA tarjoaa
          palvelujaan myös taloyhtiöille kiinteän laajakaistaverkon muodossa.
          Yleensä DNA:n perusnopeudellinen verkko kuuluu taloyhtiön osakkaille
          vastikkeeseen, joskin nopeutta voi myös muuttaa asukkaan
          vaatimuksesta. Taloyhtiöille suoraan tarjotut verkkoratkaisut tekevät
          palveluista edullisempia yksittäisille käyttäjille, ja DNA
          mainostaakin, että taloyhtiösopimuksen ansiosta kuukausihinnat voivat
          olla jopa puolet normaalia halvempia. DNA on keskittynyt aktiivisesti
          kehittämään omaa 5G-verkkoaan, ja DNA:n kaupallinen 5G-verkko lienee
          toimitusvalmiudessa muutaman vuoden sisään, kunhan puhelinvalmistajien
          laitetarjonta kehittyy 5G-verkoille sopivaksi.
        </TextArea>
        <Header3>Elisa</Header3>
        <OperatorImage imgName={"elisaoperaattori.jpg"} />
        <TextArea>
          <a href="/elisa/">Elisa eli entinen Helsingin Puhelin Oyj</a> on
          täysin suomalainen matkapuhelinoperaattori, joka toimii myös laajemmin
          tietoliikenteen ja erilaisten nettipalvelujen tarjoajana. DNA:sta ja
          Teliasta poiketen Elisa toimii pääasiassa vain Suomessa ja Virossa,
          eikä ole laajentanut toimintaansa samalla tavalla muihin Euroopan
          maihin. Elisa on Suomen suurin operaattori, jonka kuluttajille
          tarkoitettua palvelubrändejä ovat olleet vuosien varrella esimerkiksi
          Radiolinja, Kolumbus ja edelleen toiminnassa oleva Saunalahti. Nimi
          Elisa tulee sanoista elektroninen integroitu
          sanomanvälitysjärjestelmä, jonka juuret ovat syvällä suomalaisen
          tietoliikennetekniikan historiassa.
        </TextArea>
        <TextArea>
          Monille suomalaisille etenkin Radiolinjan tv-mainokset ja Freemanin
          kappale "Ajetaan tandemilla" ovat jääneet 90-luvulta mieleen.
          Radiolinjan verkossa tehtiin myös maailman ensimmäinen kaupallinen
          GSM-teknologiaa käyttävä puhelu vuonna 1991. Nykyisistä
          mainoskampanjoista Elisan tunnetuin kampanja lienee Elisan
          Hintasaarnaajat, joka matkii nykyajan lähetyssaarnaajien tapaa tulla
          kotiovelle jakamaan uskonsanomaa. Kyseistä mainoskampanjaa on kuvattu
          sekä Suomessa että Yhdysvalloissa. Kampanja on voittanut monia
          palkintoja, ja sitä on esitetty useissa eri medioissa, kuten
          televisiokanavilla sekä cameo-rooleina esityksissä.
        </TextArea>
        <TextArea>
          Elisa tarjoaa kuluttajille nettiyhteyteen liittyviä palveluja
          pääasiassa Elisa Saunalahti -liittymien muodossa, jonka lisäksi sen
          palveluvalikoimaan kuuluvat Elisa Viihde, Elisa Kirja ja erilliset
          kaapeli-TV-ratkaisut. Lisäksi Elisa myy muiden
          matkapuhelinoperaattorien tapaan palvelutuotepaketteja, joissa on
          yhdistettynä sekä liittymä että älypuhelin tai tabletti. Tällöin
          asiakkaat saavat päätelaitteet myös osamaksulla.
          Saunalahti-tuotenimikkeen alla ovat sekä puhelinliittymät,
          mobiililaajakaistaliittymät, kiinteän laajakaistan liittymät, että
          hybridilaajakaistat. Puhelinliittymistä Saunalahti käyttää
          yleisbrändiä Saunalahti Huoleton, joka vastaa ominaisuuksiltaan Telian
          Rajaton -liittymää sekä hinnoittelultaan että palvelumalliltaan.
        </TextArea>
        <TextArea>
          Lisäksi Saunalahdella on oma Saunalahti Tarkka-liittymäsarja, jonka
          liittymät on tarkoitettu asiakkaille, jotka haluavat maksaa
          palveluista niiden käytön perusteella. Kiinteistä verkoista Elisa
          tarjoaa sekä kiinteää laajakaistaa että erillistä talokaapelia, ja
          myös nettiliittymän voi tilata prepaid-versiona, johon kuuluu myös
          erillinen WLAN-reititin. Elisa on kehittänyt 5G-verkkoaan jo
          aikaisessa vaiheessa, ja avasi kaupallisen verkkonsa Suomessa
          Tampereelle jo vuonna 2018. Elisa on Suomen johtavia verkkokehittäjiä,
          ja toiminut vuosien varrella yhteistyössä muun muassa Nokian kanssa.
        </TextArea>
        <Header3>Moi mobiili</Header3>
        <OperatorImage imgName={"moioperaattori.jpg"} />
        <TextArea>
          Suurimpien matkapuhelinoperaattorien kannoilla on muutamia pienempiä
          operaattoreita, joista mielenkiintoisin on{" "}
          <a href="/moi/">palveluoperaattori Moi Mobiili</a>. Moi Mobiilin
          tarina alkoi vasta muutama vuosi sitten, tarkemmin vuonna 2015,
          jolloin joukko kokeneita televiestintäalan ammattilaisia muun muassa
          Telialta, Soneralta, Nokialta ja DNA:lta päättivät tuoda markkinoille
          asiakaslähtöisen operaattorin. Alun perin yrityksessä työskenteli vain
          muutamia kymmeniä henkilöitä. Yrityksen liittymien suosio kasvoi
          kuitenkin nopeasti, ja parin vuoden aikana matkapuhelinoperaattori sai
          myytyä kymmeniä tuhansia liittymiä sekä yksityisasiakkaille että
          pienyrityksille vain muutaman hengen tiimillä.
        </TextArea>
        <TextArea>
          Yritys siirtyi DNA:n omistukseen vuonna 2020. Moi mobiili eroaa muista
          matkapuhelinoperaattoreista etenkin sen vuoksi, että isoista
          matkapuhelinoperaattoreista poiketen yrityksellä ei ole omaa
          televerkkoa, vaan se vuokraa televerkkopalvelut muilta
          verkko-operaattoreilta ja myy niitä eteenpäin kuluttajille ja
          yrityksille. Lisäksi muista matkapuhelinoperaattoreista poiketen Moi
          mobiilin palvelut hinnoitellaan hieman eri tavalla, sillä datakiintiöt
          voidaan jakaa useamman SIM-kortin kesken, tai vaihtoehtoisesti tilata
          suoraan rajattoman ja kiinteähintaisen liittymän. Myös puhelut ja
          tekstiviestit maksavat erikseen.
          <ul>
            <li>
              Asiakkaiden kokemukset Moi-mobiilin palveluista ovat pääasiassa
              hyvin myönteisiä
            </li>
            <li>
              Mun Moi -tilinhallinnan kautta asiakas pääsee seuraamaan
              SIM-korttiensa datankulutusta
            </li>
            <li>Yrityksille Moi mobiili tarjoaa nopeita 4G-liittymiä</li>
            <li>
              Myös yritysliittymien käyttö perustuu poolimalliin, jossa monta
              SIM-korttia voidaan laskuttaa samassa datapaketissa
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Moin asiakkailla on suuria operaattoreita enemmän valinnanvaraa
          rakentaa operaattorin tarjoamista tuotteista juuri itselle sopiva
          palvelupaketti, jolloin palvelujen karsiutuessa ei tule maksettua
          turhasta. Jos esimerkiksi haluaa puhua paljon, mutta ei käyttää
          nettiä, voi harkita kiinteähintaisten puhelujen hankkimista. Samalle
          datamäärälle on myös mahdollista tilata useampia SIM-kortteja, jotka
          kuitenkin toimivat eri numeroilla, mutta kuitenkin samalla tavalla
          kuin yksittäisten liittymien SIM-kortit. Tästä johtuen käytännössä
          samaa Moi mobiilin liittymää voi käyttää vaikka koko perhe.
        </TextArea>
        <TextArea>
          Moi mobiililla on tarjonnassaan useita erilaisia kännykkäliittymiä,
          jotka eroavat toisistaan hintansa ja nettikäytettävyytensä puolesta.
          Esimerkiksi Moin Kuuden euron simmi maksaa 6 euroa kuukaudessa,
          sisältäen 4 gigaa dataa, kun taas Rajattoman reilu ja Rajaton turbo
          -liittymät pitävät sisällään rajattoman määrän dataa. Rajaton turbo on
          yksi matkapuhelinliittymien halvimmista liittymistä, joissa on rajaton
          300 megan nettiyhteys. Koska Moilla ei ole omaa verkkoa, se käyttää
          DNA:n 4G LTE-verkkoa, joka on kattavuudeltaan yksi Suomen parhaista.
        </TextArea>
        <Header4>Mikä operaattoreista sopii juuri sinulle?</Header4>
        <TextArea>
          Matkapuhelinoperaattoreita valittaessa kannattaa eri liittymien
          vertailuun käyttää aikaa, vaikka lähtökohtaisesti lähes kaikki
          Suomessa toimivat matkapuhelinliittymät ajavat kyllä asiansa.
          Puhelinoperaattorien toiminnassa ja tarjotuissa tuotteissa on
          kuitenkin jonkin verran eroavaisuuksia, joten
          matkapuhelinoperaattoreiden valinnassa kannattaa ottaa huomioon
          muutama asia. Ensinnäkin on hyvä pohtia, minkälainen oma tarpeesi, ja
          mitkä palvelut sinulle riittävät. Esimerkiksi jos käytät nettiä
          pääasiassa puhelimella, voit valita pelkän mobiililaajakaistaliittymän
          ilman kiinteää nettiä kotiin. Tämä voi olla hyvä valinta esimerkiksi
          opiskelijalle.
        </TextArea>
        <TextArea>
          Jos taas esimerkiksi työsi vaatii jatkuvaa tietokoneen käyttöä, voi
          mobiililaajakaistalla työskenteleminen olla välillä hermoja raastavaa
          puuhaa. Kokenut netin ja läppärin käyttäjä ostaa paketin, jossa tulee
          sekä kiinteä netti kotiin että mobiililaajakaista kännykälle. Tällöin
          mobiililaajakaistaa voi käyttää myös esimerkiksi mökillä tai muissa
          tapauksissa, kun tarvitsee nettiä tien päällä. Toinen tärkeä tekijä on
          se, mihin nettiä käytät. Jos normaalisti käytät nettiä vain
          työasioiden hoitamiseen, et tarvitse välttämättä 300 megan yhteyttä.
        </TextArea>
        <TextArea>
          Toinen ääripää on, jos harrastat esimerkiksi pelaamista tai
          striimausta, tai tykkäät katsoa muutenkin paljon videoita. Tällöin
          hyvä yhteys on todella tärkeää, sillä harva haluaa katsoa 4K-laatuista
          kuvaa pätkivällä yhteydellä. Suomessa toimii käytännössä kolme
          operaattoria, joilla on omat televerkot. Riippumatta siitä, ostatko
          siis Elisan, DNA:n tai Telian, vai jonkun pienemmän operaattorin
          liittymän, käytät näiden kolmen ison operaattorin verkkoa. Kaikkien
          operaattorien verkot kattavat vähintään 96 prosenttia Suomesta, joskin
          katvealueita saattaa esiintyä pienemmissä taajamissa.
        </TextArea>
        <TextArea>
          On myös hyvä pohtia sitä, pitäisikö koko kotitalouden liittymät
          vaihtaa samalla kertaa. Nykypäivänä kaikki liittymät voi vaihtaa niin,
          että oma numero siirtyy toiseen liittymään, ja monilla
          matkapuhelinoperaattoreilla on erillisiä pakettihinnoitteluja
          tilanteisiin, joissa liittymä ostetaan monelle ihmiselle kerralla.
          Esimerkiksi Moi mobiili tarjoaa erilaisia ratkaisuja monen eri
          SIM-kortin käyttöön samasta liittymästä. Muita perheille tarkoitettuja
          ratkaisuja ovat esimerkiksi Telian Liittymä lapselle -palvelu, johon
          voidaan hankkia lisäksi turvapaketti haitallisen sisällön varalta tai
          erillisellä saldorajalla.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Kaikki Suomessa toimivat matkapuhelinoperaattorit ovat
          lähtökohtaisesti todella hyviä, ja niiden palvelut toimivat lähes
          missä tahansa Suomessa. Palvelukattavuudeltaan yritykset ovatkin
          maailman kärkitasoa, jonka lisäksi Suomessa nettiliittymät ovat
          maailman edullisimpien ja nopeimpien joukossa.
        </TextArea>
        <TextArea>
          Tämän lisäksi Suomessa nettiliittymät ovat maailman edullisimpien ja
          nopeimpien joukossa. Monissa muissa maissa sekä Euroopassa tarvitsee
          netin käytöstä etenkin mobiiliverkoissa maksaa käytön mukaisesti, kun
          taas Suomessa kaikilta suosituimmilta matkapuhelinoperaattoreilta
          löytyy mahdollisuus valita kiinteähintainen netti myös mobiiliin.
          Suomen mobiili- ja nettiteknologia on maailman kärkitasoa, jonka
          ansiosta sen käyttäminen on vaivatonta ja nopeaa. Valitse siis mikä
          tahansa puhelinoperaattori ja voit olla melko varma, että ainakin
          kaikki peruspalvelut toimivat moitteettomasti.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(filter: {}, sort: { fields: price, order: ASC }) {
      nodes {
        benefits
        callPrice
        id
        img
        internetSpeed
        internet
        name
        price
        textPrice
        type
        url
        openFee
      }
    }
    # telia: allPlansJson(filter: { img: { eq: "telia.jpg" } }) {
    #   edges {
    #     node {
    #       benefits
    #       callPrice
    #       id
    #       img
    #       internetSpeed
    #       internet
    #       name
    #       price
    #       textPrice
    #       type
    #       url
    #       openFee
    #     }
    #   }
    # }
    # elisa: allPlansJson(filter: { img: { eq: "dna.jpg" } }) {
    #   edges {
    #     node {
    #       benefits
    #       callPrice
    #       id
    #       img
    #       internetSpeed
    #       internet
    #       name
    #       price
    #       textPrice
    #       type
    #       url
    #       openFee
    #     }
    #   }
    # }
    # dna: allPlansJson(filter: { img: { eq: "moi.jpg" } }) {
    #   edges {
    #     node {
    #       benefits
    #       callPrice
    #       id
    #       img
    #       internetSpeed
    #       internet
    #       name
    #       price
    #       textPrice
    #       type
    #       url
    #       openFee
    #     }
    #   }
    # }
    # moi: allPlansJson(filter: { img: { eq: "elisa.jpg" } }) {
    #   edges {
    #     node {
    #       benefits
    #       callPrice
    #       id
    #       img
    #       internetSpeed
    #       internet
    #       name
    #       price
    #       textPrice
    #       type
    #       url
    #       openFee
    #     }
    #   }
    # }
  }
`

export default FrontPage
